import { nanoid } from 'nanoid';

import { getCookie, MIXPANEL_SESSION, setCookie } from '../../utils/cookie';
import { hostUrl } from '../../utils/getServerUrl';
import { ENABLE_FULLSTORY_FOR_PROPHECY_EMAIL } from '../../utils/localstorage-keys';

export const get_Session_id = () => {
  return 'prophecy-' + nanoid(10);
};

export const getNameFromEmail = (email: string) => email.split('@')?.[0];

export const MIXPANEL___TOKEN = 'c1bf58f29206c58a3cfb97ec539d7635';

export const FullStoryAllowedDomains = [
  'beta.app.prophecy.io',
  'onboarding.dev.cloud.prophecy.io',
  'app.prophecy.io',
  'uitesting.prophecy.io'
];
export const fullStoryNameSpace = 'prophecy_ff';
export const fullStoryOrgID = 'o-1HYGPZ-na1';

export const isFSEnabledForProphecyEmail = localStorage.getItem(ENABLE_FULLSTORY_FOR_PROPHECY_EMAIL) === 'true';

export const getMixpanelApIhost = () => {
  return `${hostUrl}/api/mp`;
};

export const getCurrentTimeStamp = () => {
  return String(new Date().getTime() / 1000);
};

export const getSessionValue = () => {
  let currentSession = getCookie(MIXPANEL_SESSION);
  if (!currentSession) {
    currentSession = get_Session_id();
    setCookie(MIXPANEL_SESSION, currentSession, 30);
  }
  return currentSession;
};
