import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { preventAutoCloseTriggerFocus } from '@prophecy/utils/dom';
import { useFocusTrap } from '@prophecy/utils/react/focus';
import utilify from '@prophecy/utils/react/utilify';
import { Close as RadixClose, Overlay, Portal, Root, Trigger } from '@radix-ui/react-dialog';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { AlertTriangleIcon, CheckCircleBrokenIcon, InfoCircleIcon, XCloseIcon } from '../Icons';
import { Stack } from '../Layout';
import { BaseColor, theme } from '../theme';
import { Text } from '../Typography/Text';
import { CancelButton, OkButton, StyledChildrenWrap, StyledContent, StyledDialog, StyledFooter, StyledIconContainer, StyledLabel, StyledOverlay } from './styled';
import { DialogComponentNames, tokens } from './tokens';
import { DialogSize, IconPlacement } from './types';
const Close = RadixClose;
const StyledCrossIcon = styled(Button) `
  position: absolute;
  top: ${theme.spaces.x20};
  right: ${theme.spaces.x20};
`;
export const DialogTitleContainer = styled(Stack) ``;
const DialogContent = React.forwardRef((_a, forwardedRef) => {
    var { size, footer, overlayClassName, children, open, showCloseIcon, rootClassName, container } = _a, props = __rest(_a, ["size", "footer", "overlayClassName", "children", "open", "showCloseIcon", "rootClassName", "container"]);
    return (_jsx(Portal, { children: _jsxs(Overlay, { className: rootClassName, children: [_jsx(StyledOverlay, { "data-component": DialogComponentNames.overlay, className: overlayClassName, "data-state": open ? 'open' : 'closed' }), _jsxs(StyledDialog, Object.assign({ "data-component": DialogComponentNames.content }, props, { size: size, ref: forwardedRef, children: [_jsxs(Stack, { direction: 'vertical', height: '100%', align: 'space-between', alignY: 'stretch', children: [_jsx(StyledContent, { grow: '1', ref: container, tabIndex: -1, children: children }), footer && _jsx(StyledFooter, { children: footer })] }), showCloseIcon && (_jsx(Close, { asChild: true, children: _jsx(StyledCrossIcon, { "aria-label": 'close-dialog', icon: _jsx(XCloseIcon, { type: 'default' }), variant: 'tertiaryGrey', shape: 'circle', size: 'm' }) }))] }))] }) }));
});
export const IconMap = {
    Warning: AlertTriangleIcon,
    Alert: AlertTriangleIcon,
    Info: InfoCircleIcon,
    Success: CheckCircleBrokenIcon
};
const AlignMap = {
    center: 'center',
    left: 'start',
    right: 'space-between'
};
export const Dialog = (_a) => {
    var { size = DialogSize.m, children, trigger, closeButton = {}, okButton = {}, ButtonsContainer, footer, closable = true, open, onClose, icon, onOk, iconPlacement = IconPlacement.left, title, subTitle, overlayClassName, showCloseIcon, className, rootClassName, isBlocking, onCloseAutoFocus } = _a, restProps = __rest(_a, ["size", "children", "trigger", "closeButton", "okButton", "ButtonsContainer", "footer", "closable", "open", "onClose", "icon", "onOk", "iconPlacement", "title", "subTitle", "overlayClassName", "showCloseIcon", "className", "rootClassName", "isBlocking", "onCloseAutoFocus"]);
    useEffect(() => {
        if (!isBlocking)
            return;
        document.body.dataset.hasBlockingDialog = '1';
        return () => {
            delete document.body.dataset.hasBlockingDialog;
        };
    }, [isBlocking]);
    const contentContainer = useFocusTrap(Boolean(open));
    const okButtonRef = useRef(null);
    const cancelButtonRef = useRef(null);
    useEffect(() => {
        const containerElm = contentContainer.current;
        if (!containerElm || !open)
            return;
        if (!okButtonRef.current || !cancelButtonRef.current)
            return;
        // focus on primary dialog button if the content doesn't have any input elements
        if (!containerElm.querySelector('input, select, textarea')) {
            const button = okButtonRef.current || cancelButtonRef.current;
            button.focus();
        }
    }, [contentContainer, open]);
    const onOpenChange = (open) => {
        if (!open && closable) {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    };
    const isBlock = Boolean((closeButton === null || closeButton === void 0 ? void 0 : closeButton.block) || (okButton === null || okButton === void 0 ? void 0 : okButton.block));
    //button block property overrieds some of the required styles, override to false in button props.
    const buttons = (_jsxs(_Fragment, { children: [okButton && (_jsx(OkButton, Object.assign({ variant: 'primary' }, okButton, { ref: okButtonRef, block: false, onClick: onOk, isBlock: isBlock, children: okButton.children ? okButton.children : 'OK' }))), closeButton && (_jsx(Close, { asChild: true, children: _jsx(CancelButton, Object.assign({ variant: 'secondaryGrey' }, closeButton, { ref: cancelButtonRef, block: false, isBlock: isBlock, children: closeButton.children ? closeButton.children : 'Cancel' })) }))] }));
    const buttonWrapper = ButtonsContainer ? (_jsx(ButtonsContainer, { children: buttons })) : (_jsx(Stack, { direction: 'horizontal', gap: theme.spaces.x12, align: 'end', alignY: 'center', children: buttons }));
    return (_jsxs(Root, Object.assign({ open: open }, restProps, { onOpenChange: onOpenChange, children: [trigger && _jsx(Trigger, { asChild: true, children: trigger }), _jsxs(DialogContent, { className: className, rootClassName: rootClassName, overlayClassName: overlayClassName, size: size, footer: footer, open: Boolean(open), container: contentContainer, showCloseIcon: showCloseIcon, onCloseAutoFocus: onCloseAutoFocus || preventAutoCloseTriggerFocus, children: [_jsxs(Stack, { direction: iconPlacement === IconPlacement.center ? 'vertical' : 'horizontal', gap: theme.spaces.x24, align: AlignMap[iconPlacement], alignY: subTitle ? 'start' : 'center', children: [iconPlacement === 'left' || iconPlacement === 'center' ? icon : null, _jsxs(DialogTitleContainer, { direction: 'vertical', gap: theme.spaces.x12, align: 'start', alignY: 'start', children: [title && (_jsx(Text, { "data-component": DialogComponentNames.title, weight: tokens.Dialog.Content.headingFontWeight, tone: tokens.Dialog.Content.headingColor, level: 'lg', children: title })), subTitle && (_jsx(StyledLabel, { "data-component": DialogComponentNames.subTitle, level: 'sm', children: subTitle }))] }), iconPlacement === 'right' ? icon : null] }), _jsx(StyledChildrenWrap, { children: children }), buttonWrapper] })] })));
};
const dialog = utilify(({ props, render, unmount }) => {
    return new Promise((resolve) => {
        const renderDialog = (_a) => {
            var { children } = _a, _props = __rest(_a, ["children"]);
            const onOk = () => {
                var _a;
                (_a = _props.onOk) === null || _a === void 0 ? void 0 : _a.call(_props);
                resolve(true);
                unmount();
            };
            const onClose = () => {
                var _a;
                (_a = _props.onClose) === null || _a === void 0 ? void 0 : _a.call(_props);
                resolve(false);
                unmount();
            };
            const CustomDialog = props.Component || Dialog;
            render(_jsx(CustomDialog, Object.assign({}, _props, { children: typeof children === 'function' ? children({ onOk, onClose }) : children, open: true, onOk: onOk, onClose: onClose, isBlocking: true })), { unmountOnNavigation: true });
        };
        renderDialog(props);
    });
});
Dialog.alert = (_a) => {
    var { iconPlacement = 'left' } = _a, props = __rest(_a, ["iconPlacement"]);
    return dialog(Object.assign(Object.assign({}, props), { iconPlacement, icon: (_jsx(StyledIconContainer, { iconPlacement: iconPlacement, tone: BaseColor.error, children: _jsx(IconMap.Alert, { type: 'default' }) })) }));
};
Dialog.success = (_a) => {
    var { iconPlacement = 'left' } = _a, props = __rest(_a, ["iconPlacement"]);
    return dialog(Object.assign({ iconPlacement, icon: (_jsx(StyledIconContainer, { iconPlacement: iconPlacement, tone: BaseColor.success, children: _jsx(IconMap.Success, { type: 'default' }) })), closeButton: null }, props));
};
Dialog.warning = (_a) => {
    var { iconPlacement = 'left' } = _a, props = __rest(_a, ["iconPlacement"]);
    return dialog(Object.assign({ iconPlacement, icon: (_jsx(StyledIconContainer, { iconPlacement: iconPlacement, tone: BaseColor.warning, children: _jsx(IconMap.Warning, { type: 'default' }) })) }, props));
};
Dialog.info = (_a) => {
    var { iconPlacement = 'left', closeButton = null } = _a, props = __rest(_a, ["iconPlacement", "closeButton"]);
    return dialog(Object.assign({ iconPlacement, icon: (_jsx(StyledIconContainer, { iconPlacement: iconPlacement, tone: BaseColor.primary, children: _jsx(IconMap.Info, { type: 'default' }) })), closeButton }, props));
};
Dialog.open = dialog;
export function isDialogOpened() {
    return Boolean(document.querySelector(`[data-radix-focus-guard]`) && document.querySelector('[role="dialog"]'));
}
